import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './index.css';
import * as serviceWorker from './serviceWorker';
import { AppContextProvider } from "./context/app/app-context";
import { Initialization } from '@mit/aws-react';
import { useAppInitializer } from './services/initializer';
import appConfig from './app-config-global';

const App = React.lazy(async () => await import('./app').then(module => ({ default: module.App })))

const WrappedApp: FC = () => {
  const { initializeApp } = useAppInitializer();

  return (
      <Switch>
        <Route path="*">
          <Initialization
            App={App}
            appConfigData={appConfig}
            appInitializer={initializeApp}
            loaderProps={{
              backgroundColor: "#8d3433",
              contactEmail: "help@mit.edu",
              name: "Leave Supervisor Maintenance",
              type: "Default",
            }}
          />
        </Route>
      </Switch>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <Router>
        <WrappedApp />
      </Router>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
