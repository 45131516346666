import authProvider from "./auth-provider";
import apiConfigs from "./api-config";

export default class BaseApi {
    readonly apiHost: string
    readonly apiPathDigitalId: string
    readonly apiPathFeature: string
    readonly apiPathSapProxy: string


    constructor() {
        const isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false
        const configKey = (isGlobalEnabled ? 'global' : 'regional')

        this.apiHost = apiConfigs[configKey].apiHost ?? ''
        this.apiPathDigitalId = apiConfigs[configKey].paths.digitalId ?? ''
        this.apiPathFeature = apiConfigs[configKey].paths.feature  ?? ''
        this.apiPathSapProxy = apiConfigs[configKey].paths.sapProxy ?? ''
    }

    checkStatusCode(status: number): boolean {
        return status >= 200 && status < 300
    }

    requestHeaderGet = async (moreHeaders?: any): Promise<RequestInit> => {
        let token = await authProvider.getToken();
        const options: RequestInit = {
            method: "GET",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                ...moreHeaders
            })
        }
        return options
    }

    requestHeaderPost = async (requestBody: any, moreHeaders?: any): Promise<RequestInit> => {
        let token = await authProvider.getToken();
        const options: RequestInit = {
            method: "POST",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                ...moreHeaders
            }),
            body: moreHeaders && moreHeaders['Content-Type'] && moreHeaders['Content-Type'] !== 'application/json' ? requestBody : JSON.stringify(requestBody)
        };
        return options
    }

    requestHeaderPatch = async (requestBody: any, moreHeaders?: any): Promise<RequestInit> => {
        let token = await authProvider.getToken();
        const options: RequestInit = {
            method: "PATCH",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                ...moreHeaders
            }),
            body: moreHeaders && moreHeaders['Content-Type'] && moreHeaders['Content-Type'] !== 'application/json' ? requestBody : JSON.stringify(requestBody)
        };
        return options
    }

    async genericResponse(response: any) {
        if (this.checkStatusCode(response.status)) {
            return {
                success: true
            }
        } else {
            return {
                error: true
            }
        }
    }
}



// import { BaseApiController, AuthProvider, AppConfig } from '@mit/aws-react'
// import appConfig from 'app-config-global'
// import apiConfig from 'Api/api-config'

// // Override any methods from TemplateConstant BaseController here. Extend all other controllers off this controller
// export class BaseController extends BaseApiController {
//   public readonly appConfig: AppConfig
//   public readonly apiAppConfig: any
//   public readonly apiHost: string
//   public readonly apiPathDigitalId: string

//   readonly apiPathAccount: string

//   constructor () {
//     super()

//     this.appConfig = (appConfig as unknown) as AppConfig

//     const isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false
//     const configKey = (isGlobalEnabled ? 'global' : 'regional')

//     this.apiHost = apiConfig[configKey].apiHost ?? ''
//     this.apiPathDigitalId = apiConfig[configKey].paths.digitalId ?? ''
//     this.apiPathAccount = apiConfig[configKey].paths.account
//   }

//   public async getAuthorizationHeaders (): Promise<Record<string, string>> {
//     const token = await new AuthProvider(this.appConfig).getToken()

//     return {
//       Authorization: `Bearer ${token}`
//     }
//   }
// }
