const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "digitalId": "digital-id-v1",
      "feature": "feature-v1",
      "sapProxy": "sap-proxy-v2"
    }
  },
  "global": {
    "apiHost": "https://leave-supervisor-maintenance.mit.edu/api",
    "paths": {
      "digitalId": "digital-id/v1",
      "feature": "feature/v1",
      "sapProxy": "sap-proxy/v2"
    }
  }
};
export default apiConfigs;