import { AuthProvider } from "@mit/aws-react";
import appConfig from "../app-config-global";

class AuthProviderLocal {

    getToken = async (): Promise<any> => {
        const authorizationToken = await new AuthProvider(appConfig).getToken()
        return authorizationToken;
    }

    signOut = async (): Promise<any> => {
        await new AuthProvider(appConfig).signOut()
    }
}

const authProvider = new AuthProviderLocal()
export default authProvider;