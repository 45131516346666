import { useAppDispatch, useAppState } from "../context/app/app-context";
import AUTH_TYPES from "../model/authorizations.types";
import { appInitialized } from "@mit/aws-react";
import { useEffect, useState } from "react";
import { leaveApi, featureApi, userApi } from "../api";

interface UseAppInitializationReturn {
    initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        appInitialized({
          isLoading: true,
          hasAccess: false,
          hasError: false
        })
      }, [])

    const populateDepartments = (dispatch: any, auths: any, authType: string, dispatchAction: string, departmentResponse: any) => {
        if (!departmentResponse.error) {
            const items = departmentResponse.items.map((item) => {
                return {
                    value: item.org_unit,
                    label: item.org_unit_name,
                }
            })
            if (departmentResponse.items.length > 0) {
                auths.push(authType)
            }
            dispatch({ type: dispatchAction, value: items })
        } else {
            dispatch({ type: 'update_app_error', value: true })
            dispatch({ type: 'update_app_error_text', value: departmentResponse.errorText })
        }
    }

    const initializeApp = async (): Promise<void> => {
        let hasAccess = false
        let isActive = true;
        const profileResponse = await userApi.fetchProfile();
        if(profileResponse.error) {isActive = false; return}
        else {
            dispatch({ type: 'set_profile', value: profileResponse.person })
        }

        const featuresResponse = await featureApi.fetchFeatures()

        if(featuresResponse.error) {isActive = false; return}
        else {
            dispatch({ type: 'set_is_active', value:featuresResponse.features.active})
            let auths: string[] = []

            if (featuresResponse.features.vacation_active) {
                const timePortalInitializeResponse = await leaveApi.fetchInitialize()
                if (!timePortalInitializeResponse.error) {
                    if (timePortalInitializeResponse.is_super_sick_admin === true) {
                        auths.push(AUTH_TYPES.sick_admin)
                    }
                    if (timePortalInitializeResponse.is_dept_vaca_admin === true ||
                        timePortalInitializeResponse.is_super_vaca_admin === true) {
                        auths.push(AUTH_TYPES.vaca_admin)
                    }
                }
            }

            const departmentResponse = await leaveApi.fetchLeaveOrgUnits()

            populateDepartments(dispatch, auths, AUTH_TYPES.sick_admin, 'update_departments_leave', departmentResponse)

            if (featuresResponse.features.vacation_active && auths.includes(AUTH_TYPES.vaca_admin)) {
                const vacationDepartmentResponse = await leaveApi.fetchVacationOrgUnits()
                populateDepartments(dispatch, auths, AUTH_TYPES.vaca_admin, 'update_departments_vacation', vacationDepartmentResponse)
            }

            dispatch({ type: 'update_authorized', value: auths })
            hasAccess = auths.length !== 0;
        }

        dispatch({ type: 'set_is_active', value: isActive })
        dispatch({ type: 'set_app_loaded', value: true })

        appInitialized({
            isLoading: false,
            hasAccess,
            hasError: false
        });
    }

    return { initializeApp }
} 